import { Component, OnInit } from '@angular/core';
import { formatDate, PlatformLocation  } from '@angular/common';
import {Router} from "@angular/router"
import { DataService } from 'src/app/services';
import { Base64 } from 'js-base64';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  today=new Date();

  constructor(
    private router:Router,
    private dataService: DataService
  ) { }

  ngOnInit() {
  }


  clickKampalaGu(){
    var traveldate = formatDate(new Date(),"yyyy-MM-dd","en-KE");

    let formdata={
      'pickup' : 'Kampala',
      'dropoff' : 'Gulu',
      'pickupname' : 'Kampala',
      'dropoffname' : 'Gulu',
      'traveldate' :  traveldate,
      'returndate': '',
      'returning' : false
    }

    this.getTopRoutes(formdata)


  }

  clickKampalaAd(){
    var traveldate = formatDate(new Date(),"yyyy-MM-dd","en-KE");

    let formdata={
      'pickup' : 'Kampala',
      'dropoff' : 'Adjumani',
      'pickupname' : 'Kampala',
      'dropoffname' : 'Adjumani',
      'traveldate' :  traveldate,
      'returndate': '',
      'returning' : false
    }

    this.getTopRoutes(formdata)

  }

  clickKampalaMy(){
    var traveldate = formatDate(new Date(),"yyyy-MM-dd","en-KE");

    let formdata={
      'pickup' : 'Kampala',
      'dropoff' : 'Moyo',
      'pickupname' : 'Kampala',
      'dropoffname' : 'Moyo',
      'traveldate' :  traveldate,
      'returndate': '',
      'returning' : false
    }

    
    this.getTopRoutes(formdata)
  }

  clickTopCity(depature,destination){
    var traveldate = formatDate(this.today,"yyyy-MM-dd","en-KE");
    let formdata={
        'pickup' : depature,
        'dropoff' : destination,
        'pickupname' :depature,
        'dropoffname' : destination,
        'traveldate' : traveldate,
        'returndate': '',
        'returning' : false
      }
      this.getTopRoutes(formdata)
}

  clickKampalaAr(){
    var traveldate = formatDate(new Date(),"yyyy-MM-dd","en-KE");

    let formdata={
      'pickup' : 'Kampala',
      'dropoff' : 'Arua',
      'pickupname' : 'Kampala',
      'dropoffname' : 'Arua',
      'traveldate' :  traveldate,
      'returndate': '',
      'returning' : false
    }

    
    this.getTopRoutes(formdata)
  }

  

  getTopRoutes(formdata){
    let p=Base64.encode(JSON.stringify(formdata));
    this.dataService.changeSearchMessage(p);
    this.router.navigate(['/booking'])
  }

}
