const homeUrl= 'https://uganda-api.buupass.com/marketplace/';
// const homeUrl= 'https://6c2a-197-232-135-232.ngrok.io/marketplace/';
 
const v2P='https://pay-v2.buupass.com/payments/';
const usersApi='https://v2-user-management.buupass.com/user-management/users/';
const usersApiLogin='https://v2-user-management.buupass.com/user-management/users/login';
export const environment = {
  production: false,
  api_key:'XHBpSFNB.9KaQyjDz6jumOywBStTGDuQ7rof87J9c',
  getDestinations: homeUrl+'buses/cities/aliases',
  searchBuses: homeUrl+'buses/',
  getSeats: homeUrl + 'buses/seats',
  bookingApi: homeUrl + 'booking/',
  bookingApiV2: homeUrl+'website/api_booking_v2',
  confirmApi: homeUrl+'website/api_mpesa_query_status',
  easyUrl: homeUrl+'payment/request',
  paymentQuery: 'https://pay.buupass.com/query_status',
  v2confirm: v2P+'c2b/mtn/transaction/status/',
  modernCoastConfirm: homeUrl+'booking/payment/status/',
  retrieveEmail: usersApi+'retrieve/',
  resetPassword: usersApi+'password-reset',
  signUp: usersApi+'registration',
  loginUser:usersApi+'login',
  getProfile: usersApi+'profile',
  usersApiLogin:usersApiLogin,
  getTrips: homeUrl+'booking/search?search='
};
