<div id="top"></div>
<div class=" hidden-md hidden-lg ">
    <app-nav ></app-nav>
</div>
<div class="visible-lg visible-md">
  <app-headerhome ></app-headerhome>
</div>
<div id="container _mt-40 bg-buupass ">
        <ol class="arrows-pay _mt-90 _ml-60">
           <li><a href="#">Home</a></li>
           <li><a href="#">Booking</a></li>
           <li><a href="#">Passenger Details</a></li>
           <li><a href="#">Review and Pay</a></li>
        </ol>

        <div class="bus-booking-page searchFont _pt-15">
            <div>
                <div class="page-content container row _ml-80">
                    <div class="col-md-7">

                      <div class="row">
                        <div class="bus-booking-summ _p-10">
                          <div class="col-md-12">
                            <div class="theme-payment-page-sections-item-pay ">
                              <div class="theme-payment-page-signin">
                                <div class="theme-payment-page-signin-body">
                                  <h4 class="theme-payment-page-signin-title review-header _mb-10">Passenger Details </h4>
                                </div>
                              </div>
                            </div>
                            <div class="theme-search-results-sidebar-section-checkbox-list-items">
                                <div class="checkbox theme-search-results-sidebar-section-checkbox-list-item"  >
                                  
                                  <div  class="_ml-20 _mt-30 _mb-30">
                                    <div class="card">
                                      <div class="card-body">
                                        <div class="fare-dtl ">
                                          <div class="c-accordion-item expanded collapsible diff-collapsed ">
                                              <div class="accordion-body " style="display: block;">
                                                  <div *ngFor="let p of bookingdata.passengers;let m =index" class="fare-dtl">
                                                    <div class="fare-dtl-row total">
                                                        <div class=" left-wing ">
                                                          <span class="fare-total">{{m+1}}. {{p.full_name}}</span> ({{p.phone_number}})
                                                       </div>
                                                        <div class="right-wing">
                                                            <div class="c-price-display u-text-ellipsis " title=""><span
                                                                    class="icon"><i class="ixi-icon-inr icon"
                                                                        aria-hidden="true"></i></span>
                                                                        <span class="">Seat {{ p.seat.id}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            </div>
                          </div>
                          <!-- <div class="col-md-12">
                            <div class="theme-payment-page-sections-item ">
                              <div class="theme-payment-page-signin">
                                <div class="theme-payment-page-signin-body">
                                  <h4 class="theme-payment-page-signin-title review-header _mb-10">Passenger Details</h4>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="fare-dtl ">
                                  <div class="c-accordion-item expanded collapsible diff-collapsed ">
                                      <div class="accordion-body " style="display: block;">
                                          <div *ngFor="let p of bookingdata.passengers" class="fare-dtl">
                                            <div class="fare-dtl-row total">
                                                <div class=" left-wing ">
                                                  <span class="fare-total">{{p.full_name}}</span> ({{p.phone_number}})
                                               </div>
                                                <div class="right-wing">
                                                    <div class="c-price-display u-text-ellipsis " title=""><span
                                                            class="icon"><i class="ixi-icon-inr icon"
                                                                aria-hidden="true"></i></span>
                                                                <span class="">Seat {{ p.seat.id}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                            </div>
                          </div> -->
                        </div>
                      </div>
                      <div class="row">
                        <div class="bus-booking-summ _p-10">
                          <div class="col-md-12">
                            <div class="theme-payment-page-sections-item-pay ">
                              <div class="theme-payment-page-signin">
                                <div class="theme-payment-page-signin-body">
                                  <h4 class="theme-payment-page-signin-title review-header _mb-10">Payment</h4>
                                </div>
                              </div>
                            </div>
                            <div class="theme-search-results-sidebar-section-checkbox-list-items">
                                <div class="checkbox theme-search-results-sidebar-section-checkbox-list-item"  >
                                  <div id="collapseMpesa" [ngbCollapse]="isCollapsed" class="_ml-20 _mt-30 _mb-30">
                                    <div class="card">
                                      <div class="card-body">
                                        <h5>Payment Details</h5>
                                        <p class="_mb-20"> 
                                            <span class="left-wing"><i class="fa fa-user"></i> {{this.bookingdata.payee.name}}</span><br>
                                            <span><i class="fa fa-phone"></i> {{this.bookingdata.payee.phone_number}}</span>
                                          </p>
                                        <h5>Payment Method</h5>
                                        <p class="checkbox-title"> Pay with <img height="30" src="assets/img/brands/pay-with.webp" alt="Pay with MTN MOMO"> </p>

                                         An stk push will be sent on your mobile number
                                         <br>
                                         Before you proceed, please confirm you have enough money in your MTN MoMo.
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <!-- <div class="checkbox theme-search-results-sidebar-section-checkbox-list-item" >
                                  <div class="_mb-10">
                                    <label  class="switch ">
                                      <input (click)="isCollapsedVoomaClicked()" type="radio" name="switch-choice" >
                                      <span class="slider round"></span>
                                    </label>
                                    <span class="checkbox-title _ml-10"><img src="assets/img/brands/vooma1.jpg" alt=""> KCB VOOMA </span>
                                  </div>
                                  <div id="collapseVooma" [ngbCollapse]="isCollapsedVooma" class="_ml-20 _mt-10 _mb-20">
                                    <div class="card">
                                      <div class="card-body">
                                         An stk push will be sent on your mobile number
                                         <br>
                                          Before you proceed, please confirm you have enough money in your KCB account.
                                      </div>
                                    </div>
                                  </div>
                                </div> -->
                                <!-- <div class="checkbox theme-search-results-sidebar-section-checkbox-list-item" >
                                  <div class="_mb-10">
                                    <label  class="switch ">
                                      <input (click)="isCollapsedCardClicked()" type="radio" name="switch-choice" >
                                      <span class="slider round"></span>
                                    </label>
                                    <span class="checkbox-title _ml-10"><img src="assets/img/brands/visa.jpg" alt="">Mastercard or Visa card </span>
                                  </div>
                                    <div id="collapseCard" [ngbCollapse]="isCollapsedCard" class="_ml-20 _mt-10 _mb-20">
                                      <div class="card">
                                        <div class="card-body">
                                           Before you proceed, please confirm you have enough money in your  card.
                                        </div>
                                      </div>
                                    </div>
                                </div> -->



                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="row">
                        <div class="bus-booking-summ">
                          <div class="col-md-12">
                            <div class="theme-payment-page-sections-item-pay ">
                              <div class="theme-payment-page-signin">
                                <div class="theme-payment-page-signin-body">
                                  <h4 class="theme-payment-page-signin-title review-header _mb-10">Use a discount </h4>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 _mb-10">
                                    <div class="theme-search-area-section first theme-search-area-section-line">
                                      <label class="theme-search-area-section-label _mb-10">Enter your voucher code.</label>
                                      <div class="theme-search-area-section-inner">
                                        <input class="theme-search-area-section-input typeahead"
                                         name="voucherCode"
                                         type="text"
                                         id="voucherCode">
                                      </div>
                                    </div>
                            </div>
                          </div>
                        </div>
                      </div> -->
                    </div>
                    <div class="col-md-4 _ml-20" style="transform: translateY(0px);">
                      <div class="row">

                      <div class="bus-booking-summ _p-20">
                        <div class="col-md-12">
                          <h4 class="theme-payment-page-signin-title review-header _mb-10">Trip Details</h4>
                          <p><strong>Travel on:</strong> {{bookingdetails.search_details.traveldate}}</p>
                          <p><strong>Bus operator:</strong> {{bookingdetails.result.operator.alias | titlecase }}</p>
                          <div class="bus-info-cntr col-md-6 u-ib u-v-align-top">
                            <span class="u-ib u-v-align-middle bus-agent-name">
                                <span class="u-ib u-v-align-middle bus-ac-row _pb-10">
                                    Depature 
                                </span>
                            </span>
                            <div class="info pickup font-trip">
                                <div class="">
                                  <span>{{bookingdetails.search_details.pickupname}}<br>
                                    <span>{{tConvert(bookingdetails.result.departure_time)}}</span>
                                    <p>{{bookingdetails.search_details.traveldate}} </p>
                                  </span></div>
                            </div>
                          </div>
                          <div class="bus-info-cntr col-md-6 u-ib u-v-align-top">
                            <span class="u-ib u-v-align-middle bus-agent-name">
                                <span class="u-ib u-v-align-middle bus-ac-row _pb-10">
                                  Dropoff 
                                </span>
                            </span>
                            <div class="info pickup font-trip">
                                <div class="">
                                  <span>{{bookingdetails.search_details.dropoffname}}<br>
                                    <span>{{tConvert(bookingdetails.result.arrival_time)}}</span>
                                    <p>{{bookingdetails.search_details.traveldate}} </p>
                                  </span></div>
                            </div>
                          </div>
                          
                        </div>
                          
                          
                      </div>
                      </div>
                      <div class="row">
                        <div class="fare-dtl-acc _p-20">
                            <div class="c-accordion-item expanded collapsible diff-collapsed ">
                                <div class="accordion-hdr  collapsible">
                                    <div class="accordion-hdr-cntnt">
                                         <div class="fare-hdr">
                                            <strong *ngIf="is_return">Return Ticket</strong>
                                            <strong *ngIf="!is_return">One-Way Ticket</strong>
                                            </div>
                                    </div><span class="ixi-icon-chevron"></span>
                                </div>
                                <div class="accordion-body " style="display: block;">
                                    <div class="fare-dtl">
                                       <div class="fare-dtl-row total">
                                          <div class="left-wing">Selected seat(s) no.</div>
                                          <div class="right-wing">
                                              <div class="c-price-display u-text-ellipsis " title="">
                                                <span class="icon">
                                                  <i class="ixi-icon-inr icon" aria-hidden="true"></i>
                                                </span><span class="_mr-5" *ngFor="let seat of bookingdetails.seatsSelected">{{seat.id}}</span>
                                              </div>
                                          </div>
                                      </div>
                                      <div *ngIf="is_return" class="fare-dtl-row total">
                                          <div class="left-wing">Selected return seat(s) no.</div>
                                          <div class="right-wing">
                                              <div class="c-price-display u-text-ellipsis " title="">
                                                <span class="icon">
                                                  <i class="ixi-icon-inr icon" aria-hidden="true"></i>
                                                </span><span class="_pd-10 " *ngFor="let seat of return_trip.seatsSelected">{{seat.id}}</span>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="fare-dtl-row total">
                                          <div class=" left-wing "><span class="fare-total">Total</span></div>
                                          <div class="right-wing">
                                              <div class="c-price-display u-text-ellipsis " title=""><span
                                                      class="icon"><i class="ixi-icon-inr icon"
                                                          aria-hidden="true"></i></span><span class="">UGX {{paytotalfare}}</span>
                                              </div>
                                          </div>
                                      </div>
                                    </div>
                                </div>
                                <div class="disclaimer">Please Note: Once seats are selected
                                    and paid for, they cannot be changed. Tickets are non-refundable.</div>
                                    <div *ngIf="isError" class="invalid-feedback">
                                      <div class="invalid-select _ml-10"  >{{errorMessage}}</div>
                                      <p><a class="text-blue _ml-10 _pd-10 pull-right" href="/booking"><i  class="fas fa-edit"></i> Modify Booking</a></p>
                                    </div>
                                </div>
                        </div>
                      </div>
                      <div class="cnfrm-cntnr">
                          <div class="btn-cntnr right">
                              <button  (click)="submitBooking()"     class="c-btn u-link ">
                                  <div class="u-ripple"><span class="u-ripple-circle"></span></div>
                                  <img *ngIf="loading" src="assets/img/gifs/76.gif">
                                  <span *ngIf="!loading">PAY</span>
                              </button>
                          </div>
                      </div>
                    </div>
                </div>



            </div>
        </div>
    </div>
<app-footer></app-footer>
